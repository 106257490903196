import {Transaction, Session} from '@emporos/api-enterprise';
import assert from 'assert';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {TransactionConsolidate} from '../api';
import {generateSessionKey} from '../utils/session';
import {
  useAuthentication,
  useTransactionsConfig,
  useNetworkAvailable,
  useGlobalData,
} from './';
import {sessionLocaldb} from '../localDb/sessionLocaldb';
import {refreshAuthIfNecessary} from '../utils';
import {useOidcAuth} from './OidcAuthProvider';
import {DIFactory} from '../DIFactory';

export type {Session, Transaction};

export type SessionUpdates =
  | Partial<Session>
  | ((prevSession: Session) => Partial<Session>);

export type TransactionUpdates =
  | Partial<Transaction>
  | ((prevTransaction: Transaction) => Partial<Transaction>);
export interface TransactionContextProps {
  currentTransactionIndex?: number;
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  currentTransactionId: string;
  setCurrentTransactionId: Dispatch<SetStateAction<string>>;
  selectedPayment: string;
  setSelectedPayment: Dispatch<SetStateAction<string>>;
  savingSession: boolean;
}

const noop = () => undefined;

export const TransactionsContext = createContext<TransactionContextProps>({
  session: null as unknown as Session,
  setSession: noop,
  currentTransactionId: '',
  setCurrentTransactionId: noop,
  selectedPayment: '',
  setSelectedPayment: noop,
  savingSession: false,
});

export function TransactionsStateProvider(props: {
  children?: React.ReactNode;
}): JSX.Element {
  const {session, setSession, loading} = useTransactionsConfig();
  const [currentTransactionId, setCurrentTransactionId] = useState('');
  const [selectedPayment, setSelectedPayment] = useState('');
  const [savingSession, setsavingSession] = useState(false);
  const [CompletingTransaction, setCompletingTransaction] = useState(false);
  const {user, logout} = useAuthentication();
  const {paymentTendersResult} = useGlobalData();
  const {online, userNeedsRefreshed, setUserNeedsRefreshed} =
    useNetworkAvailable();
  const auth = useOidcAuth();
  const authStorageService = DIFactory.getAuthStorageService();

  const buildLocalSession = useCallback(() => {
    const sessionKey = generateSessionKey(user);
    const token = user ? user.access_token : '';
    const userSub = user ? user.profile['sub'] : '';
    const sessionId = session ? session.sessionId : '';
    const accessCode = session ? session.accessCode : '';
    return new sessionLocaldb(
      sessionKey,
      token,
      sessionId,
      currentTransactionId,
      accessCode,
      userSub,
      paymentTendersResult?.data ?? [],
    );
  }, [user, session, paymentTendersResult]);

  assert(
    session !== null,
    'Internal Error: rendered session app tree without active session',
  );

  useEffect(() => {
    if (!savingSession && session && currentTransactionId && !loading) {
      const transaction = session.transactions.find(
        i => i.transactionId === currentTransactionId,
      );
      if (!transaction) return;

      setsavingSession(true);
      const isCompleted =
        (transaction as TransactionConsolidate).isCompleted ?? false;
      setCompletingTransaction(isCompleted);
      refreshAuthIfNecessary(
        online,
        user,
        userNeedsRefreshed,
        setUserNeedsRefreshed,
        auth.signinRefresh,
        authStorageService.storeUser,
        authStorageService.removeAuthInfo,
        logout,
        session.accessCode,
      ).then(user => {
        const localsession = buildLocalSession();
        if (user) localsession.resetToken(user.access_token);
        localsession?.saveSession(session, online, isCompleted).then(() => {
          setsavingSession(false);
          if (CompletingTransaction) {
            setCompletingTransaction(false);
          }
        });
      });
    }
  }, [session]);

  return (
    <TransactionsContext.Provider
      value={{
        session,
        setSession: setSession as Dispatch<SetStateAction<Session>>,
        currentTransactionId,
        setCurrentTransactionId,
        selectedPayment,
        setSelectedPayment,
        savingSession,
      }}
    >
      {props.children}
    </TransactionsContext.Provider>
  );
}

export const useTransactionsState = (): TransactionContextProps =>
  useContext(TransactionsContext);
