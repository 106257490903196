import {
  Button,
  FooterGroup,
  Gutter,
  Header,
  Modal,
  ScrollContainer,
  Stack,
  Variant as BV,
} from '@emporos/components';
import {RouteComponentProps} from '@reach/router';
import assert from 'assert';
import {isEqual} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {
  OfflineTransaction,
  TransactionConsolidate,
  useNetworkAvailable,
  useTransactionsConfig,
  useTransactionsState,
} from '../../../';
import {CreateSession, SessionConfig} from '../../';
import {Session, TransactionStatusEnum} from '@emporos/api-enterprise';

function _sessionHasPendingTransactions(session: Session) {
  if (
    session.transactions.filter(
      transaction =>
        !(transaction as OfflineTransaction).isCompleted &&
        !(transaction as TransactionConsolidate).isDeleted &&
        !(
          transaction.status === TransactionStatusEnum.Complete ||
          transaction.status === TransactionStatusEnum.Error ||
          transaction.status === TransactionStatusEnum.Accepted ||
          transaction.status === TransactionStatusEnum.Deleted
        ),
    ).length > 0
  )
    return true;

  return false;
}

export const testing_sessionHasPendingTransactions = (
  session: Session,
): boolean => {
  return _sessionHasPendingTransactions(session);
};

function SettingsComponent(_: RouteComponentProps): JSX.Element {
  const {
    closeSession,
    closeSessionLoading,
    session,
    updatePaymentDeviceAddress,
    updatePaymentDeviceAddressLoading,
    loading,
  } = useTransactionsConfig();
  const {savingSession} = useTransactionsState();
  const {online} = useNetworkAvailable();

  const [config, setConfig] = useState<SessionConfig>({
    site: null,
    station: null,
    paymentDeviceAddress: null,
    paymentDevicePort: null,
    till: null,
    tillStartingAmount: null,
  });
  const [originalConfig, setOriginalConfig] = useState<SessionConfig>();
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState(false);
  const [openSalesModal, setOpenSalesModal] = useState(false);
  const [openOfflineModal, setOpenOfflineModal] = useState(false);
  const [sessionBusy, setSessionBusy] = useState(loading || savingSession);

  assert(
    session !== null,
    'Internal Error: tried to render Settings without a session',
  );

  const {
    site,
    startingCashBalance,
    station,
    till,
    paymentDeviceAddress,
    paymentDevicePort,
  } = session;

  useEffect(() => {
    const _config = {
      ...config,
      site,
      station,
      till,
      paymentDeviceAddress: paymentDeviceAddress || '',
      paymentDevicePort: paymentDevicePort || '',
      tillStartingAmount: startingCashBalance || 0,
    };
    setConfig(_config);
    setOriginalConfig(_config);
  }, [session]);

  useEffect(() => {
    setEnableConfirm(!isEqual(originalConfig, config));
  }, [config]);

  useEffect(() => {
    setSessionBusy(loading || savingSession);
  }, [loading, savingSession]);

  useEffect(() => {
    if (online) {
      setOpenOfflineModal(false);
    }

    if (!online && openSalesModal) {
      setOpenSalesModal(false);
      setOpenOfflineModal(true);
    }

    if (!online && openConfirmCloseModal) {
      setOpenConfirmCloseModal(false);
      setOpenOfflineModal(true);
    }
  }, [online]);

  const onCloseSession = () => {
    if (!online) {
      setOpenOfflineModal(true);
    } else if (_sessionHasPendingTransactions(session) || sessionBusy) {
      setOpenSalesModal(true);
    } else {
      setOpenConfirmCloseModal(true);
    }
  };

  const onConfirm = useCallback(async () => {
    const {paymentDeviceAddress} = config;
    await updatePaymentDeviceAddress(session, paymentDeviceAddress as string);
  }, [config, session]);

  const closeSessionFn = () => {
    setOpenConfirmCloseModal(false);
    if (online) closeSession();
    else setOpenOfflineModal(true);
  };

  return (
    <>
      <Stack gutter={Gutter.None} style={{height: '100%'}}>
        <Header title="My Session" />
        <ScrollContainer style={{paddingBottom: 16}}>
          <CreateSession
            configSession
            loading={closeSessionLoading}
            sites={[site]}
            stations={[station]}
            config={config}
            onChange={setConfig}
            tills={[till]}
          />
        </ScrollContainer>
        <FooterGroup>
          <Button
            type="button"
            variant={BV.Danger}
            flex
            onClick={onCloseSession}
            loading={closeSessionLoading}
          >
            Close Session
          </Button>
          <Button
            flex
            disabled={!enableConfirm}
            loading={updatePaymentDeviceAddressLoading}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </FooterGroup>
      </Stack>
      <Modal
        visible={openConfirmCloseModal}
        data-testid="Modal__ConfirmClose"
        icon="CashRegister"
        color="primary"
        title="Close My Session"
        subtitle="Please confirm you want to close your session. You cannot reopen this session once closed."
        buttonText="Confirm"
        onCancel={() => setOpenConfirmCloseModal(false)}
        onContinue={closeSessionFn}
      />
      <Modal
        visible={openSalesModal}
        data-testid="Modal__OpenSales"
        icon="Warning"
        color="error"
        title="Session Has Open or Unsynced Sales"
        subtitle="Your session still has sales that are open or unsynced. Please complete or wait until they are all fully synced."
        buttonText="Okay"
        onContinue={() => setOpenSalesModal(false)}
      />
      <Modal
        visible={openOfflineModal}
        data-testid="Modal__Offline"
        icon="Warning"
        color="error"
        title="Session Cannot Be Closed"
        subtitle="Your session cannot be closed while offline. Please go online and try again."
        buttonText="Okay"
        onContinue={() => setOpenOfflineModal(false)}
      />
    </>
  );
}

export const SettingsHome = SettingsComponent;
