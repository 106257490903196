import {User} from '@emporos/hilo-auth';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {IdleTimer} from '../components/IdleTimer';
import {
  AuthClaim,
  getDeviceFingerprint,
  PreLogin,
  refreshAuthIfNecessary,
  TEST_USER_KEY,
} from '../';
import {
  NavigationLogTypes,
  useAnalyticsProvider,
  useLog,
  useNetworkAvailable,
} from './';
import {useOidcAuth} from './OidcAuthProvider';
import {DIFactory} from '../DIFactory';

interface Context {
  user: User | null;
  login: () => Promise<void>;
  logout: () => void;
  deviceId: string;
}

export const SESSION_TIMEOUT = 1000 * 60 * 10; // 10 minutes

export const AuthenticationContext = createContext<Context>({
  user: null,
  login: () => Promise.reject('Unable to find AuthenticationProvider.'),
  logout: () => () => {
    return;
  },
  deviceId: '',
});

interface Props {
  children: JSX.Element;
}

export function AuthenticationProvider({children}: Props): JSX.Element {
  const [deviceId, setDeviceId] = useState('');
  const {identify, reset} = useAnalyticsProvider();
  const {logUserSelection} = useLog();
  const {online, userNeedsRefreshed, setUserNeedsRefreshed} =
    useNetworkAvailable();
  const auth = useOidcAuth();
  const authStorageService = DIFactory.getAuthStorageService();
  const user: User = useMemo(() => {
    const serializedTestUser = localStorage.getItem(TEST_USER_KEY);
    return (
      auth.user || (serializedTestUser ? JSON.parse(serializedTestUser) : null)
    );
  }, [auth.user]);
  const refreshingUser = useRef(false);

  useEffect(() => {
    setDeviceId(getDeviceFingerprint());
  }, []);

  const login = useCallback(async () => {
    logUserSelection(NavigationLogTypes.UserLogin);
    if (online) {
      await auth.signinRedirect();
    }
  }, [user, online]);

  const logout = useCallback(async () => {
    logUserSelection(NavigationLogTypes.UserLogout);
    localStorage.removeItem(TEST_USER_KEY);
    setUserNeedsRefreshed(null);
    reset();
    if (online) (async () => await auth.signoutRedirect())();
    else auth.removeUser();
  }, [user, online]);

  const api = useMemo(
    () => ({
      user,
      login,
      logout,
      deviceId,
    }),
    [user, login, logout, deviceId],
  );

  useEffect(() => {
    if (online && userNeedsRefreshed && user && !refreshingUser.current) {
      refreshingUser.current = true;
      refreshAuthIfNecessary(
        online,
        user,
        userNeedsRefreshed,
        setUserNeedsRefreshed,
        auth.signinRefresh,
        authStorageService.storeUser,
        authStorageService.removeAuthInfo,
        logout,
      ).then(refreshedUser => {
        refreshingUser.current = false;
        if (refreshedUser)
          identify(`${refreshedUser.profile[AuthClaim.UserId]}`, refreshedUser);
      });
    } else if (user) {
      identify(`${user.profile[AuthClaim.UserId]}`, user);
    }
  }, [logout, userNeedsRefreshed]);

  return (
    <AuthenticationContext.Provider value={api}>
      {user ? (
        <>
          {children}
          <IdleTimer
            // TODO: make timeout configurable
            timeout={SESSION_TIMEOUT}
            onIdle={logout}
            debounce={250}
          />
        </>
      ) : (
        <PreLogin onRedirect={login} />
      )}
    </AuthenticationContext.Provider>
  );
}
export const useAuthentication = (): Context =>
  useContext(AuthenticationContext);
