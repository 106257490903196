import {
  BarcodeCollection,
  Transaction,
  TransactionItem,
  OtcItem,
  Setting,
} from '@emporos/api-enterprise';
import {ScanResult} from '@emporos/barcodes';
import {
  Button,
  ButtonShape,
  ButtonSize as Size,
  FooterGroup,
  Gutter,
  Header,
  Illustration,
  Modal,
  Row,
  RowItem,
  ScrollContainer,
  SmallSidebarWrapper,
  Stack,
  Text,
  TextVariant as TV,
  Variant as BV,
  ButtonSquareText,
} from '@emporos/components';
import {PriceFooter} from '@emporos/components/src/PriceFooter';
import {NumberPanel} from '@emporos/components/src/Numberpad';
import {PageBarcodeScanner} from '@emporos/components-pos';
import {NavigateFn} from '@reach/router';
import {Dispatch, memo, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {
  AnalyticType,
  formatCustomerName,
  formatMedication,
  transactionItemIcons,
  TransactionLogTypes,
  TransactionUpdates,
  MultiSelectMode,
  MultiSelectResult,
  OfflineSynced,
  TotalsResult,
  updateDiscount,
  updatePrice,
  updateQuantity,
  useAlertState,
  useAnalyticsProvider,
  useLog,
  useNetworkAvailable,
  ITEM_TYPE_IDS,
} from '../../../';
import {mapComplianceIndicators, mapIdCheckIndicators} from './';
import {mergeTaxes} from '../../../utils/taxes';

const RightSidebar = styled(SmallSidebarWrapper)`
  padding: 0 20px 20px;
  @media (max-width: 1034px) {
    padding: 0 12px 20px 12px;
  }
`;

export const ActionButton = styled(ButtonSquareText)`
  margin-top: 20px;
  width: 96px;
  @media (max-width: 1077px) {
    width: 85px;
  }
  @media (max-width: 1047px) {
    width: 75px;
  }
`;

export interface TransactionPageProps {
  transaction: Transaction;
  canDeleteTransaction: boolean;
  totals: TotalsResult;
  settings: Setting[];
  otcItems: OtcItem[];
  barcodes: BarcodeCollection;
  multiselect: MultiSelectResult<string>;
  navigate: NavigateFn;
  onRemoveTransaction: (transaction: Transaction) => void;
  onUpdateTransaction: (updates: TransactionUpdates) => void;
  onUpdateTransactionItem: (
    item: TransactionItem,
    updates: Partial<TransactionItem>,
  ) => void;
  setCurrentTransactionId: Dispatch<SetStateAction<string>>;
  onScan: (scanResult: ScanResult) => void;
}

function TransactionComponent({
  transaction,
  canDeleteTransaction,
  totals,
  settings,
  multiselect,
  navigate,
  onRemoveTransaction,
  onUpdateTransaction,
  onUpdateTransactionItem,
  onScan,
}: TransactionPageProps): JSX.Element {
  const {notification} = useAlertState();
  const {track} = useAnalyticsProvider();
  const {logUserSelection} = useLog();
  const {online} = useNetworkAvailable();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [showChangePriceNumpad, setShowChangePriceNumpad] = useState(false);
  const [showDiscountNumpad, setShowDiscountNumpad] = useState(false);
  const [showQuantityNumpad, setShowQuantityNumpad] = useState(false);
  const [numpadNumber, setNumpadNumber] = useState('');

  const {isNplex, isControlledSubstance, isRequiredAge} =
    mapIdCheckIndicators(transaction);
  const {showCounsel, showHipaa, showRelationship} = mapComplianceIndicators(
    transaction,
    settings,
  );

  const {transactionId, items, customer, taxes} = transaction;

  const single = multiselect.single();

  const showComplianceUrl =
    showCounsel || showHipaa || showRelationship
      ? './payments/compliance'
      : './payments/customer-payment';

  const url =
    !transaction.identification &&
    ((isNplex && transaction.pseCheckResult !== 1) ||
      isControlledSubstance ||
      isRequiredAge)
      ? './payments/id-check'
      : showComplianceUrl;

  const createClickHandlersForItem = (item: TransactionItem) => {
    return {
      onClick: () => {
        if (showChangePriceNumpad || showDiscountNumpad || showQuantityNumpad) {
          return;
        }

        if (multiselect.isSelected(item.transactionItemId)) {
          multiselect.deselect(item.transactionItemId);
        } else if (multiselect.mode === MultiSelectMode.Multiple) {
          multiselect.selectMultiple(item.transactionItemId);
        } else {
          multiselect.selectOne(item.transactionItemId);
        }
      },
    };
  };

  const continueTransaction = () => {
    if (isNplex && transaction.pseCheckResult !== 1 && !online) {
      notification({
        title: 'Offline Error: PSE Items',
        description:
          'This transaction cannot move forward while offline with unapproved PSE items in the cart.',
        type: 'warning',
        icon: 'Warning',
      });
      return;
    }
    return navigate(url, {
      state: {animatePanel: true},
    });
  };

  return (
    <>
      <Stack
        data-testid="transaction-page"
        gutter={Gutter.None}
        style={{height: '100%'}}
      >
        <Header
          title={customer ? formatCustomerName(customer) : 'General Sale'}
          badgeText={transaction.roomNumber || ''}
          data-testid="navbar-header"
        >
          <ButtonShape
            size={Size.Small}
            icon="User"
            onClick={() => navigate('customer')}
            data-testid="customer-info-btn"
          />
          <ButtonShape
            disabled={!canDeleteTransaction}
            size={Size.Small}
            icon="Trash"
            onClick={() => setDeleteModalOpen(true)}
            data-testid="delete"
          />
        </Header>

        <Row
          gutter={Gutter.XXL}
          style={{height: '100%', marginTop: 20, minHeight: 0}}
          noWrap
        >
          <Stack style={{flex: 1}}>
            {!items.length && (
              <Stack
                align="center"
                justify="center"
                gutter={Gutter.None}
                style={{flex: 1}}
              >
                <Illustration illustration="AddProduct" />
                <Text
                  variant={TV.Main}
                  data-testid="no-items-added"
                  align="center"
                >
                  No items added
                </Text>
              </Stack>
            )}

            {!!items.length && (
              <ScrollContainer>
                {items.some(({rx}) => rx) && (
                  <Stack gutter={Gutter.S} style={{marginBottom: 16}}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Prescriptions
                    </Text>
                    {items
                      .filter(({rx}) => rx)
                      .map(item => (
                        <RowItem
                          key={item.transactionItemId}
                          title={formatMedication(item.description2 || '')}
                          subtitle={item.receiptDescription || ''}
                          rightIcons={transactionItemIcons(item)}
                          rightText={`$${item.price.toFixed(2)}`}
                          selected={multiselect.isSelected(
                            item.transactionItemId,
                          )}
                          {...createClickHandlersForItem(item)}
                        />
                      ))}
                  </Stack>
                )}
                {items.some(({rx}) => !rx) && (
                  <Stack gutter={Gutter.S}>
                    <Text
                      variant={TV.Caption}
                      style={{marginBottom: '-4px', paddingLeft: 16}}
                    >
                      Over the Counter
                    </Text>
                    {items
                      .filter(({rx}) => !rx)
                      .map(item => (
                        <RowItem
                          key={item.transactionItemId}
                          inactive={
                            item.itemTypeID === ITEM_TYPE_IDS.METH_FREE &&
                            transaction.pseCheckResult === 1
                          }
                          variant="generic"
                          title={formatMedication(item.description || '')}
                          subtitle={item.itemNumber || ''}
                          rightIcons={transactionItemIcons(item)}
                          rightText={`$${item.price.toFixed(2)}`}
                          rightBadgeColor="success"
                          rightBadgeText={
                            item.discountPercent
                              ? `${(item.discountPercent * 100).toFixed(0)}%`
                              : ''
                          }
                          rightStrikeText={
                            item.price !== item.listPrice && item.discount === 0
                              ? `$${item.listPrice.toFixed(2)}`
                              : ''
                          }
                          selected={multiselect.isSelected(
                            item.transactionItemId,
                          )}
                          quantity={
                            item.quantity > 0 ? item.quantity.toString() : ''
                          }
                          {...createClickHandlersForItem(item)}
                        />
                      ))}
                  </Stack>
                )}
              </ScrollContainer>
            )}

            <Stack style={{marginTop: 0}}>
              <PriceFooter
                totals={totals}
                style={{marginBottom: 16, marginTop: 0}}
              />
              <FooterGroup>
                <Button
                  data-testid="otc-search-btn"
                  variant={BV.Secondary}
                  flex
                  onClick={() => navigate('otc-search')}
                >
                  OTC Search
                </Button>
                <Button
                  flex
                  data-testid="accept-payment-btn"
                  disabled={!items.length}
                  onClick={() => {
                    onUpdateTransaction({
                      isSynced: false,
                      taxableSubTotal: totals.taxableSubTotal,
                      discount: totals.discount,
                      subTotal: totals.subTotal,
                      totalSale: totals.transactionTotal,
                      totalTax: totals.salesTax,
                      qhpRxAmount: totals.qhpRxAmount,
                      qhpAmount: totals.qhpAmount,
                      qhpRxQty: totals.qhpRxQty,
                      qhpOtherAmount: totals.qhpOtherAmount,
                      qhpOtherQty: totals.qhpOtherQty,
                      taxes: mergeTaxes(taxes, totals.transactionTaxes),
                    } as Partial<Transaction> & OfflineSynced);
                    return continueTransaction();
                  }}
                >
                  Accept Payment
                </Button>
              </FooterGroup>
            </Stack>
          </Stack>

          <RightSidebar>
            <Row justify="space-between" gutter={Gutter.None}>
              <ActionButton
                type="button"
                icon="CashRegister"
                text="Price"
                data-testid="price-button"
                disabled={
                  true || // TODO: temporarily disabled until requirements and permissions are ready
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );

                  setNumpadNumber(item ? item.price.toFixed(2) : '0.00');
                  setShowChangePriceNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Percent2"
                text="Discount"
                data-testid="discount-button"
                disabled={
                  true || // TODO: temporarily disabled until requirements and permissions are ready
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );
                  setNumpadNumber(
                    item ? (item.discountPercent * 100).toFixed(0) : '0',
                  );
                  setShowDiscountNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="Quantity"
                text="Quantity"
                data-testid="quantity-button"
                disabled={
                  !single ||
                  !transaction.items.some(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  )
                }
                onClick={() => {
                  if (!single) {
                    return;
                  }
                  const item = transaction.items.find(
                    ({transactionItemId, rx}) =>
                      !rx && transactionItemId === single,
                  );
                  setNumpadNumber(item ? item.quantity.toString() : '0');
                  setShowQuantityNumpad(true);
                }}
              />
              <ActionButton
                type="button"
                icon="XCircle"
                text="Remove"
                disabled={multiselect.size === 0}
                onClick={() => {
                  onUpdateTransaction(prevTransaction => ({
                    ...prevTransaction,
                    items: prevTransaction.items.filter(
                      transactionItem =>
                        !multiselect.isSelected(
                          transactionItem.transactionItemId,
                        ),
                    ),
                  }));
                  logUserSelection(TransactionLogTypes.RemoveItem, {
                    item: items.filter(({transactionItemId}) =>
                      multiselect.isSelected(transactionItemId),
                    ),
                  });

                  multiselect.reset();
                }}
              />
            </Row>
          </RightSidebar>
        </Row>

        {single && (
          <>
            <NumberPanel
              title="Discount"
              type="percent"
              number={numpadNumber}
              setNumber={n => setNumpadNumber(Number(n) > 100 ? '100' : n)}
              defaultValue="0%"
              onClear={() => setNumpadNumber('0')}
              onConfirm={() => {
                const item = items.find(
                  ({transactionItemId}) => transactionItemId === single,
                );
                if (!item) {
                  return;
                }
                const percent = Number(numpadNumber.replace('%', '')) / 100;
                const updatedItem = updateDiscount(item, percent);
                track(AnalyticType.ItemDiscount, {
                  item: {
                    productId: item.itemNumber || '',
                    name: item.description || '',
                  },
                  discount: percent,
                  oldPrice: item.itemListPrice,
                  price: updatedItem.extension,
                  type: item.rx ? 'prescription' : 'otc',
                });
                onUpdateTransactionItem(item, {
                  ...updatedItem,
                });
                setShowDiscountNumpad(false);
              }}
              onCancel={() => {
                setShowDiscountNumpad(false);
              }}
              open={showDiscountNumpad}
            />

            <NumberPanel
              title="Quantity"
              number={numpadNumber}
              setNumber={n => {
                const negativeQuantity = Number(n) < 1 ? '1' : n;
                setNumpadNumber(Number(n) > 99 ? '99' : negativeQuantity);
              }}
              onClear={() => setNumpadNumber('')}
              onConfirm={() => {
                const item = items.find(
                  ({transactionItemId}) => transactionItemId === single,
                );
                if (!item) {
                  return;
                }

                const quantity = Number(numpadNumber);
                const updatedItem = updateQuantity(item, quantity);
                onUpdateTransactionItem(item, {
                  ...updatedItem,
                });
                logUserSelection(TransactionLogTypes.UpdateQuantity, {
                  item: item.description || '',
                  value: quantity,
                });
                setShowQuantityNumpad(false);
              }}
              onCancel={() => {
                setShowQuantityNumpad(false);
              }}
              open={showQuantityNumpad}
            />
            {items.some(item => !item.rx) && (
              <NumberPanel
                title="Price Change"
                type="price"
                number={numpadNumber}
                setNumber={setNumpadNumber}
                defaultValue="$0.00"
                onClear={() => setNumpadNumber('0')}
                onConfirm={() => {
                  const item = items.find(
                    ({transactionItemId}) => transactionItemId === single,
                  );
                  if (!item) {
                    return;
                  }
                  const price = Number(numpadNumber);
                  const updatedItem = updatePrice(item, price);
                  track(AnalyticType.PriceChange, {
                    item: {
                      productId: item.itemNumber || '',
                      name: item.description || '',
                    },
                    oldPrice: item.itemListPrice,
                    price,
                  });
                  onUpdateTransactionItem(item, {
                    ...updatedItem,
                  });
                  setShowChangePriceNumpad(false);
                }}
                onCancel={() => {
                  setShowChangePriceNumpad(false);
                }}
                open={showChangePriceNumpad}
              />
            )}
          </>
        )}

        <Modal
          data-testid="delete-modal"
          visible={deleteModalOpen}
          icon="Trash"
          color="error"
          onCancel={() => setDeleteModalOpen(false)}
          onContinue={() => {
            onRemoveTransaction(transaction);
            logUserSelection(TransactionLogTypes.RemoveTransaction, {
              item: transactionId,
            });

            return navigate('/sales');
          }}
          title="Delete This Transaction"
          subtitle="This transaction will be deleted and cannot be recovered. Would you like to continue?"
        />
      </Stack>
      {!showChangePriceNumpad &&
        !showQuantityNumpad &&
        !showDiscountNumpad &&
        online && <PageBarcodeScanner onScan={onScan} />}
    </>
  );
}

export const TransactionPage = memo(TransactionComponent);
