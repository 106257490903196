import {
  BarcodeCollectionApiResponse,
  PaymentOptionResultListApiResponse,
  SettingListApiResponse,
} from '@emporos/api-enterprise';
import {navigate} from '@reach/router';
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ResultsOTCApiResponse,
  useAuthentication,
  useNetworkAvailable,
  useSequentialOTCApi,
  useSession,
  useTransactionsState,
} from '..';
import {useApi} from './ApiProvider';
import {useGlobalData} from './GlobalDataProvider';
import {usePMSStrategy} from '@emporos/barcodes';

type PMS = 'mckesson' | 'epic';

interface SessionDataProviderProps {
  loading: boolean;
  isError: boolean;
  retryOTCLoad: () => void;
  syncOTC: () => void;
  otcItemsResult: ResultsOTCApiResponse | void;
  settingsResult: SettingListApiResponse | void;
  paymentOptionsResult: PaymentOptionResultListApiResponse | void;
  pmsName: PMS;
  barcodeName: string;
  barcodesResult: BarcodeCollectionApiResponse | void;
  thirdPartySiteNumber: string;
  skipWillCall: boolean;
  setSkipWillCall: (skipWillCall: boolean) => void;
}

// TODO temporary hardcoded
const THIRD_PARTY_SITE_NUMBER = '104';
export const DEFAULT_PMS = 'mckesson';

export const OTC_PAGE_SIZE = 1000;

const SessionDataContext = createContext<SessionDataProviderProps>({
  loading: false,
  isError: false,
  retryOTCLoad: () => null,
  syncOTC: () => null,
  otcItemsResult: undefined,
  settingsResult: undefined,
  paymentOptionsResult: undefined,
  pmsName: DEFAULT_PMS,
  barcodeName: '',
  barcodesResult: undefined,
  thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
  skipWillCall: false,
  setSkipWillCall: () => null,
});

function SessionDataProvider({children}: {children: ReactNode}): ReactElement {
  const {session, setCurrentTransactionId, setSelectedPayment} =
    useTransactionsState();
  const {online} = useNetworkAvailable();
  const api = useApi();
  const {user} = useAuthentication();
  const sessionRef = useRef<string | null>(null);
  const [otcItems, setOtcItems] = useState<ResultsOTCApiResponse>();
  const {pendingTransactions} = useSession();
  const [starting, setStarting] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [pmsName, setPmsName] = useState<PMS>(DEFAULT_PMS);
  const [barcodeName, setBarcodeName] = useState('');
  const [skipWillCall, setSkipWillCall] = useState(false);
  const {drawerDate, siteId, stationId} = session;
  const {loading: globalDataLoading, isError: globalDataError} =
    useGlobalData();
  const {setPms} = usePMSStrategy();

  const {
    data: otcData,
    pagination: otcPagination,
    loading: otcLoading,
    partialError: otcPartialError,
    fatalError: otcFatalError,
    syncLoading: otcSyncLoading,
    syncPagination: otcSyncPagination,
    onRetry: otcOnRetry,
    onSync: onSyncOTC,
    setPage: otcNextPage,
    setSyncPage: otcNextSyncPage,
  } = useSequentialOTCApi(
    user,
    siteId,
    '1.5',
    online,
    OTC_PAGE_SIZE,
    drawerDate,
  );

  useEffect(() => {
    if (otcPagination && otcPagination?.hasNextPage) {
      otcNextPage(p => p + 1);
    }
  }, [otcPagination]);

  useEffect(() => {
    if (otcSyncPagination && otcSyncPagination.hasNextPage) {
      otcNextSyncPage(p => p + 1);
    }
  }, [otcSyncPagination]);

  useEffect(() => {
    setOtcItems({
      data: otcData,
      loading: otcLoading,
      pagination: otcPagination,
      fatalError: otcFatalError,
      partialError: otcPartialError,
      syncLoading: otcSyncLoading,
      syncPagination: otcSyncPagination,
    });
  }, [
    otcData,
    otcLoading,
    otcPagination,
    otcPartialError,
    otcFatalError,
    otcSyncLoading,
    otcSyncPagination,
  ]);

  const {
    loading: loadingSettings,
    error: errorSettings,
    data: settingsResult,
  } = api.GetSettings([
    {
      siteId,
      stationId,
    },
  ]);
  const {
    loading: loadingPaymentOptions,
    error: errorPaymentOptions,
    data: paymentOptionsResult,
  } = api.GetPaymentOptions([{siteId}]);
  const {
    loading: loadingBarcodes,
    error: errorBarcodes,
    data: barcodesResult,
  } = api.GetBarcodes([{siteId}]);

  useEffect(() => {
    setLoading(
      globalDataLoading ||
        loadingSettings ||
        loadingPaymentOptions ||
        loadingBarcodes,
    );
  }, [
    globalDataLoading,
    loadingSettings,
    loadingPaymentOptions,
    loadingBarcodes,
  ]);

  useEffect(() => {
    setIsError(
      otcFatalError.error ||
        !!globalDataError ||
        !!errorSettings ||
        !!errorPaymentOptions ||
        !!errorBarcodes,
    );
  }, [
    globalDataError,
    otcFatalError,
    errorSettings,
    errorPaymentOptions,
    errorBarcodes,
  ]);

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      if (
        session &&
        sessionRef.current !== session.sessionId &&
        !loading &&
        !isError &&
        barcodesResult
      ) {
        sessionRef.current = session.sessionId;
        const transactionWithPayment = pendingTransactions.find(
          ({payments}) => payments.length,
        );
        if (transactionWithPayment) {
          setCurrentTransactionId(transactionWithPayment.transactionId);
          const pendingPayment = transactionWithPayment.payments.find(
            ({recordStatus}) =>
              ['OverCharged', 'Pending'].includes(recordStatus),
          );
          if (pendingPayment) {
            setSelectedPayment(pendingPayment.transactionPaymentId);
            await navigate(
              '/sales/transactions/payments/customer-payment/payment-info',
              {
                replace: true,
              },
            );
          } else if (transactionWithPayment.signatures.length > 0) {
            await navigate('/sales/transactions/payments/receipts', {
              replace: true,
            });
          } else {
            await navigate('/sales/transactions/payments/customer-payment', {
              replace: true,
            });
          }
        }
        setStarting(false);
      }
    })();
  }, [user, session, sessionRef, loading, isError, barcodesResult]);

  useEffect(() => {
    if (
      settingsResult &&
      settingsResult.data &&
      barcodesResult &&
      barcodesResult.data
    ) {
      const pms = settingsResult.data.find(({name}) => name === 'PMS');
      if (!pms) throw Error('Missing PMS setting');
      const pmsValue = pms.value.toLowerCase() as PMS;
      setPmsName(pmsValue);
      setPms(pmsValue);

      const _barcodeName = barcodesResult.data.pmsBarcodeMappings.find(
        m => m.pmsName?.toLowerCase() === pms.value.toLowerCase(),
      )?.barcodeName;
      if (!_barcodeName) throw Error('Missing barcode name mapping');
      setBarcodeName(_barcodeName);
    }
  }, [settingsResult, barcodesResult]);

  return (
    <SessionDataContext.Provider
      value={{
        loading: loading || starting,
        isError,
        otcItemsResult: otcItems,
        retryOTCLoad: otcOnRetry,
        syncOTC: onSyncOTC,
        settingsResult,
        paymentOptionsResult,
        pmsName,
        barcodeName,
        barcodesResult,
        thirdPartySiteNumber: THIRD_PARTY_SITE_NUMBER,
        skipWillCall,
        setSkipWillCall,
      }}
    >
      {children}
    </SessionDataContext.Provider>
  );
}
const useSessionData = (): SessionDataProviderProps =>
  useContext(SessionDataContext);
export {SessionDataProvider, useSessionData};
